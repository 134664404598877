// components/Navigation.js
import React, { useContext, useState, useEffect }  from 'react';
import { useParams } from 'react-router-dom';
import { UserOutlined } from '@ant-design/icons';
import { GlobalContext } from '../GlobalContext';
import { useLocation } from 'react-router-dom';
import { Menu } from 'antd';

import { Link } from 'react-router-dom';
import {Dropdown, Select, Avatar } from 'antd';

import { checkLogin, logout } from '../library/account-helper';
import {lotteryList} from '../library/data-helper';
import LanguageContext from '../LanguageContext';
import './css/Navigation.css';

function Navigation() {


  const [selectedLottery, setSelectedLottery] = useState(null);
  const [lotteryListData, setLotteryListData] = useState({});
  const { setGlobalValue, getGlobalValue } = useContext(GlobalContext);
  const { language, setLanguage, t } = useContext(LanguageContext);
  const location = useLocation();


  useEffect(() => {
    const storedSelectedLottery = localStorage.getItem('selectedLottery');
  if (storedSelectedLottery !== null) {
    setSelectedLottery(storedSelectedLottery);
  }
    //lotteryList({setReturn:setLotteryListData});
    
  
  }, []);
// When the selected lottery changes, store it in localStorage
useEffect(() => {
  if (selectedLottery !== null && selectedLottery !== undefined) {
    localStorage.setItem('selectedLottery', selectedLottery);

    setGlobalValue('selectedLottery', selectedLottery);
    

  }
}, [selectedLottery]);



 useEffect(() => {

     

      setGlobalValue("loggedIn", checkLogin());

  }, [location.pathname]);



const handleLogout = () => {
  logout({onSuccess: () => { window.location.reload() }});
}

 
  const userMenu = (
    <Menu>
      <Menu.Item key="settings">
        <Link to={`/${language}/settings`}>Settings</Link>
      </Menu.Item>

      <Menu.Item key="logout">
        <a onClick={handleLogout}>Logout</a>
      </Menu.Item>
    </Menu>
  );

  return (<>
  <div className="navigation">
    <Menu mode="horizontal" className='menu-left'>
      <Menu.Item key="logo" >
        <Link to={`/${language}/`}><img src="/images/logo.png" alt="logo" style={{height:50}} /></Link>
      </Menu.Item>
      
      <Menu.Item key="strategy" >
        <Link to={`/${language}/strategy`}>{t.menufilters}</Link>
      </Menu.Item>
      <Menu.Item key="Number Patterns">
        <Link to={`/${language}/patternfinder`}>{t.menudraws}</Link>
      </Menu.Item>
      

      
      <Menu.Item key="systembuilder">
        <Link to={`/${language}/systembuilder`}>{t.menusystembuilder}</Link>
      </Menu.Item>
      
      <Menu.Item key="Statistics">
        <Link to={`/${language}/statistics`}>{t.menustatistics}</Link>
      </Menu.Item>
      </Menu>
    <Menu mode="horizontal" className="menu-right" >
       <Menu.Item key="lottery">
        <div className="label-container">
        <label>{t.labellottery}</label>
        <Select style={{width:150}}
        placeholder="Select a Lottery"
        value={selectedLottery}
        onChange={value => setSelectedLottery(value)}
      >
        {getGlobalValue("lotteryListData") !==null && getGlobalValue("lotteryListData")!==undefined && Object.entries(getGlobalValue("lotteryListData")).map(([key, lottery]) => (
          <Select.Option key={key} value={key}>{lottery.label}</Select.Option>
        ))}
      </Select>
      </div>
      </Menu.Item>
    <Menu.Item key="language">
            
       {
       /*
       <div className="label-container">
        <label>{t.labellanguage}</label> 
       <Select value={language} onChange={(e) => setLanguage(e)}>
          <option value="en">English</option>
        </Select>
        </div>
        */}
      
          </Menu.Item>
        <Menu.Item key="subscribe">
        <Link to={`/${language}/subscribe`}>{t.menusubscribe}</Link>
      </Menu.Item>
      {!getGlobalValue("loggedIn") ? (<> 

     
      <Menu.Item key="login">
        <Link to={`/${language}/login`}>{t.login}</Link>
      </Menu.Item>
   
        <Menu.Item key="register">
          <Link to={`/${language}/register`}>{t.register}</Link>
        </Menu.Item>
       
          </>):(
    <>
      <Dropdown overlay={userMenu} trigger={['click']}>
        <Menu.Item key="user">
          <Link to={`/${language}/profile`}><Avatar shape="square" size="large" icon={<UserOutlined style={{fontSize:20}} />} /></Link>
        </Menu.Item>
      </Dropdown>
    </>
  )}
    </Menu>
    </div>
    </>
  );
}

export default Navigation;