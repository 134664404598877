import React, { useEffect, useState, useContext } from 'react';

import { Table, Collapse, Select, Spin } from 'antd';
import {simulateStepsSystem, lotterySystemsList} from '../library/data-helper';
import { groupBy, map } from 'lodash';
import { GlobalContext } from '../GlobalContext';
import moment from 'moment';


  

const StrategySimulatorSystemCheck = ({dates, steps, selectedDay, t, suggestionsLengthUnique}) => {

    console.log("suggestionsLengthUnique", suggestionsLengthUnique);
    const [loadingSim, setLoadingSim] = useState(false);
    const [stepSimulateResult, setStepSimulateResult] = useState([]);
    const {getGlobalValue} = useContext(GlobalContext);
    const [systemNumberSettings, setSystemNumberSettings] = useState([{}]);
    const [lotterySystems, setLotterySystems] = useState([]);
    const lotteryListData = getGlobalValue("lotteryListData");
    const selectedLottery = getGlobalValue("selectedLottery");

     let lotterycost = 0;
    let currency = '';
   
     if (lotteryListData !== undefined && lotteryListData.hasOwnProperty(selectedLottery)) {
      lotterycost = lotteryListData[selectedLottery].costper;
      currency = lotteryListData[selectedLottery].currency;
   }

  const columns = [
    {
      title: t.date,
      dataIndex: 'date',
      key: 'date',
      sorter: (a, b) => a.date.localeCompare(b.date),
    },
    {
      title: t.systemmatches,
      dataIndex: 'matches',
      key: 'matches',
      render: matches => {
        if (Object.keys(matches.matches).length > 0) {
          return (
            <div>
              {Object.entries(matches.matches).map(([k, v]) => {
  const items = k.split('');
  return `${v} x ${items[1] !== '0' ? items[0] +"+"+ items[1] : items[0]}`;
}).join(', ')} {t.numberendings}
            </div>
          );
        }
        return '';
      },
      sorter: (a, b) => a.matches - b.matches,
      width: 'auto',
    },
    {
      title: t.system,
      dataIndex: 'system',
      key: 'system',
    },
    {
      title: t.numbers,
      dataIndex: 'numbers',
      key: 'numbers',
      render: (numbers, record) => {
      const numbersSet = new Set(record.suggestion);
      return numbers.map((number, i) => 
        <React.Fragment key={i}>
              {numbersSet.has(number) 
                ? <span style={{backgroundColor: 'yellow'}}>{number}</span> 
                : <span>{number}</span>}
              {i < numbers.length - 1 && ','}
            </React.Fragment>
      );
    },
    },
    {
    title: t.suggestions,
    dataIndex: 'suggestion',
    key: 'suggestion',
    render: (suggestion, record) => {
      const numbersSet = new Set(record.numbers);
      return (
        <>
          {suggestion.map((number, i) => 
            <React.Fragment key={i}>
              {numbersSet.has(number) 
                ? <span style={{backgroundColor: 'yellow'}}>{number}</span> 
                : <span>{number}</span>}
              {i < suggestion.length - 1 && ','}
            </React.Fragment>
          )}
          {' (' + suggestion.length + ')'}
        </>
      );
    },
  },
  ];


  useEffect(() => {
    if (selectedLottery !== null && selectedLottery !== undefined) {
    lotterySystemsList({lottery:selectedLottery, setReturn: setLotterySystems, setLoading:setLoadingSim});
    }
  }, [suggestionsLengthUnique, selectedLottery]);


  



    //const filteredStepSimulateResult = stepSimulateResult.filter(item => item.matches > 1);
    //const groupedMatches = groupBy(filteredStepSimulateResult, 'matches');
    //const matchCountData = map(groupedMatches, (value, key) => ({ matches: key, count: value.length }));
    const stepSimulateResultWithYear = stepSimulateResult.map(item => ({
      ...item,
      year: moment(item.date).year(), // Extract the year from the date
    }));
    
    const groupedByYear = groupBy(stepSimulateResultWithYear, 'year');
    
    const matchCountDataByYear = map(groupedByYear, (value, key) => {
      const matchCounts = {};
      let totalWin = 0;
      let totalSystemCost = 0;
      value.forEach(item => {
        Object.keys(item.matches.matches).forEach(match => {
          if (!matchCounts[match]) {
            matchCounts[match] = 0;
          }
          matchCounts[match] += item.matches.matches[match];
        });
        totalWin += item.matches.wintotal;
        console.log("item.systemcost", item.systemcost, item.matches.cost)
        if (parseFloat(item.systemcost)===0){
          //console.log("item.systemcost2", item.systemcost, item.matches.cost)
          totalSystemCost += item.matches.cost;
        }else{
          totalSystemCost += parseFloat(item.systemcost);
        }
        
      });
      return {
        year: key,
        data: Object.entries(matchCounts).map(([k, v]) => ({ matches: k, count: v })),
        totalWin,
        totalSystemCost,
      };
    });



    useEffect(() => {

      const storedRecords = JSON.parse(localStorage.getItem(selectedLottery+"SystemSettings")) || [];
      
      setSystemNumberSettings(storedRecords)

    }, [selectedLottery]);

    useEffect(() => {
        //const stepsJson = JSON.stringify(steps);
        //console.log(selectedDay);
        const isEmpty = systemNumberSettings.every(obj => Object.keys(obj).length === 0);
        if (systemNumberSettings.length === 0 || isEmpty) {
          return;
        }
        if (!dates || dates.length !== 2) {
          return;
        }
        // Do whatever you need with stepsJson here
      
        if (steps.length < 2) return;
    
       runSimulate();

      }, [dates, steps, selectedDay, systemNumberSettings]);

      function runSimulate() {
        simulateStepsSystem({ steps, lottery:getGlobalValue("selectedLottery"), setReturn: setStepSimulateResult, startDate: dates[0], endDate: dates[1], day: selectedDay, setLoading: setLoadingSim, systems:systemNumberSettings});
      }

    console.log(matchCountDataByYear);
    const matchCountColumns = [
      {
      title: t.matches,
      dataIndex: 'matches',
      key: 'matches',
      align: 'right',
      render: matches => {
        const items = matches.split('');
        return <strong>{items[1] !== '0' ? items[0]+"+"+items[1] : items[0]}</strong>;
      },
      width: '100px', // change the width to 'auto'
      },
      {
      title: t.count,
      dataIndex: 'count',
      key: 'count',
      },  
    ];
      
    

    const columnsSystemsNumbers = [
      {
      title: "Systemnumbers",
      dataIndex: "systemNumbers",
      key: "systemNumbers",
      sorter: (a, b) => a.systemNumbers - b.systemNumbers, // Sort ascending by default
      defaultSortOrder: 'ascend', // Sort by systemNumbers column by default  
    },
      {
      title: "Lottery Systems",
      dataIndex: "key",
      key: "key",
      render: (key, record) => {
        const matchingSystems = lotterySystems?.filter(system => system.systemnumbers === Number(record.systemNumbers));
        if (matchingSystems.length === 0) {
        return t.nosupportedsystem;
        }
        
        return (
        <Select
          style={{ width: 200 }}
          onChange={(value) => {
          const selectedSystem = matchingSystems.find((system) => system.id === value);
          if (selectedSystem) {
            const storedRecords = JSON.parse(localStorage.getItem(selectedLottery+"SystemSettings")) || [];
            const existingIndex = storedRecords.findIndex(item => item.systemnumbers === selectedSystem.systemnumbers);
            if (existingIndex !== -1) {
            storedRecords[existingIndex] = selectedSystem;
            } else {
            storedRecords.push(selectedSystem);
            }
            localStorage.setItem(selectedLottery+"SystemSettings", JSON.stringify(storedRecords));
            setSystemNumberSettings(storedRecords);
          }else if(value === null){
            const storedRecords = JSON.parse(localStorage.getItem(selectedLottery+"SystemSettings")) || [];
            const existingIndex = storedRecords.findIndex(item => item.systemnumbers === Number(record.systemNumbers));
            if (existingIndex !== -1) {
            storedRecords.splice(existingIndex, 1);
            }
            localStorage.setItem(selectedLottery+"SystemSettings", JSON.stringify(storedRecords));
            setSystemNumberSettings(storedRecords);
          }
            }}
            defaultValue={systemNumberSettings.find(item => item.systemnumbers === Number(record.systemNumbers))?.name}
            >
              <Select.Option key={null} value={null}>No System</Select.Option>
            {matchingSystems.map((system) => (
            <Select.Option key={system.id} value={system.id} style={{ whiteSpace: 'wrap', width:'100%'}}> 
          <div>{system.name}</div>
          <small className='selectdescription'> {`Numbers in row: ${system.numbersinrow}, Cost: ${currency}${system.cost === null || system.cost === 0 ? (system.rows * lotterycost).toFixed(2) : system.cost.toFixed(2)}, Rows: ${system.rows}, System numbers: ${system.systemnumbers}`}</small>
          </Select.Option>
            ))}
            
            </Select>
          )
      },
      },
    ];

    const tabItems = [
            {
        key: '1',
        label: t.systemsettings,
        children: (
          <>
            <Table
              dataSource={suggestionsLengthUnique.map((length, index) => ({
                key: index,
                systemNumbers: length,
              }))}
              pagination={false}
              columns={columnsSystemsNumbers}
              style={{ width: 'fit-content' }} 
              size="small"
            ></Table>
          </>
        ),
      },
    ];


    return (
      <> 
      
<Collapse defaultActiveKey="1" items={tabItems} />
  

           
            {loadingSim && <Spin/>}
            {systemNumberSettings.length>0 && !loadingSim &&
            <>
            <h2>{t.result}</h2>
            <div style={{ display: 'flex', overflowX: 'auto' }}>
              {matchCountDataByYear.map((yearData, index) => (
                <div key={index} style={{ flex: '0 0 auto', marginLeft: index > 0 ? '20px' : '0' }}>
                  
                  <div style={{fontSize:11}}>
                    <h2>{yearData.year}</h2>
                    <p>
                      {t.totalWin}: {selectedLottery.currency} {yearData.totalWin.toFixed(2)}
                    </p>
                    <p>
                      {t.totalSystemCost}: {yearData.totalSystemCost.toFixed(2)}
                    </p>
                    <p>
                      {t.profitloss}: <span style={{ color: yearData.totalWin - yearData.totalSystemCost < 0 ? 'red' : 'green' }}>{(yearData.totalWin - yearData.totalSystemCost).toFixed(2)}</span>
                    </p>

                  </div>
                  <Table
                    className="compact-table"
                    columns={matchCountColumns}
                    dataSource={yearData.data}
                    pagination={false}
                    
                    size="small"
                  />
                </div>
              ))}
            </div>
            
            <h3 className="title">{t.resultdetails}</h3>
            <Table className="compact-table" columns={columns} dataSource={stepSimulateResult} />
            </>
            }

  </>
    )

}

export default StrategySimulatorSystemCheck;