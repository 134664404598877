import React, { useContext, useState, useEffect }  from 'react';
import LanguageContext from '../../LanguageContext';

import {Table} from 'antd';
import { scaleLinear } from 'd3-scale';

const colorScale = scaleLinear()
  .domain([0, 10, 20, 30, 40, 50])
  .range(['lightblue','lightgreen', 'yellow', 'orange', 'red']);

function HeatMap({draws,lotteryListDataDetails}) {



    const { t } = useContext(LanguageContext);
    const [rowData, setRowData] = useState([]);
    let maxnumber = lotteryListDataDetails?.max;
    const numbers = Array.from({ length: maxnumber }, (_, i) => i + 1);
    const scores = {};
    let highestNumber = 0;
    
    const columns = [
      {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        className: 'first-column',
        width: 30,
      },
      ...numbers.map((num, index) => ({
        title: String(num),
        dataIndex: String(num),
        key: String(num),
        width:10,
        className: index % 2 === 0 ? 'light-column' : 'normal-column', // Add the CSS class to every second column
        render: (text, record) => {
          const value = record[String(num)];
          const color = colorScale(value);
          const isDrawn = draws[record?.date] ? draws[record.date].includes(num) : false;
          
          return {
            props: {
              style: { backgroundColor: color},
            },
            children: <div>{isDrawn ? num : ''}</div>,
          };
        },
      })),
    ];

    const processScores = (data) => {
      const dateScores = [];

      for (const date in data) {
          const numbers = data[date];
          
          //console.log(date, numbers)
          // Initialize scores for numbers 1-49 with a default of 0
          

          // Modify scores based on presence
          for (let i = 1; i <= maxnumber; i++) {
              if (numbers.includes(i)) {
                  scores[i] += 10; // Number is present, add 10 points
                  if (scores[i] > highestNumber) {
                      highestNumber = scores[i];
                  }
              } else {
                  if (scores[i]>=2){
                    scores[i] -= 2; // Number is not present, subtract 1 point
              }   
            }
          }
          
          //console.log(scores)
          dateScores.push({ date, ...scores });
      }

      return dateScores;
  };

  useEffect(() => {
    if (!draws) return;
    for (let i = 1; i <= maxnumber; i++) {
      scores[i] = 0;
    }
    const scoresByDate = processScores(draws);
    console.log(scoresByDate)
    setRowData(scoresByDate)

  }, [draws]);

  
  

return (
    
    <div style={{ height: '100vh', width: '99vw' }}>
    <Table className="compact-table" size="small" columns={columns} dataSource={rowData} rowClassName="bordered-cell" pagination={false} sticky scroll={{ x: 'max-content' }}/>

</div>
);



}

export default HeatMap;