import React, { useState, useEffect, useContext } from 'react';
import { Steps, Modal, Select, InputNumber, Checkbox, Form, Button, Input, Segmented, Tooltip } from 'antd';
import { PlusOutlined, SettingOutlined, DeleteOutlined, PauseCircleOutlined, PlayCircleOutlined } from '@ant-design/icons';
import {filterList, stepsList} from '../library/data-helper';
import { GlobalContext } from '../GlobalContext';
import './css/Filters.css';
import LanguageContext from '../LanguageContext';
import Draws from './Draws';
import NumberPattern from './modules/numberPatternSingle';
const { Option } = Select;

const defaultsteps = [{
  "action": "add",
  "group": "systemnumbers",
  "id": "pattern",
  "name": "Number Patterns",
  "pause": true,
  "settings": {
      "pattern": {
          "value": "0;",
          "name": "Pattern",
          "type": "pattern"
      }
  },
  "idq": 171233
},
{
  "action": "add",
  "group": "systemnumbers",
  "id": "pattern",
  "name": "Number Patterns",
  "pause": true,
  "settings": {
      "pattern": {
          "value": "0;",
          "name": "Pattern",
          "type": "pattern"
      }
  },
  "idq": 171234
},
{
  "action": "add",
  "group": "systemnumbers",
  "id": "pattern",
  "name": "Number Patterns",
  "pause": true,
  "settings": {
      "pattern": {
          "value": "0;",
          "name": "Pattern",
          "type": "pattern"
      }
  },
  "idq": 171235
}];

function StepComponent({ step, stepResult, name, settings = {}, onSettingsClick,removeStep, pauseStep }) {
  let settingsStr = "";
  if (Object.keys(settings).length) {
    const extractNameAndValue = Object.entries(settings).map(([key, setting]) => {
      return `${setting.name}: ${setting.value}`;
    });
    settingsStr = "";//extractNameAndValue.join(', ');
  }
  let countcorrect = 0;
  let totalsuggestions = 0;
  // Display the settings string
  return (
    <div style={{textAlign:'end', marginTop:-15}}>
      {step.id !== "start" && step.id !== "system" ? '' : ''}{settingsStr}
      {/*step.id !== "start" && step.id !== "end" && <Button type="link" icon={<SettingOutlined />} onClick={onSettingsClick} /> */}
      
      {stepResult?.map((results) => {
        results.steps.map((result) => {
          //patternumbers[[36, 37], [30, 31], [32, 33], [37, 38], [3, 4], [4, 5]]
        if (result.id === step.id && result.idq === step.idq && result.patternumbers?.length>0 && results.numbers?.length>0) {
          
          //const count = result.patternumbers.filter(num => results.numbers?.includes(num)).length;
          const count = result.patternumbers.filter(numArray => numArray.some(num => results.numbers?.includes(num))).length;
          countcorrect += count;
          totalsuggestions += result.patternumbers.length;
          //console.log(results.numbers, result.patternumbers, results, count)
        }
        });
            
        
      })}
      {totalsuggestions>0 && 
      <p style={{fontSize:'9pt', lineHeight:'9pt', whiteSpace:'normal', marginTop: -5}}>Correct: {countcorrect}/{totalsuggestions} ({(countcorrect/totalsuggestions*100).toFixed(1)}%)</p>
      }
    </div>
  );
}

function Simulator() {
  const {t} = useContext(LanguageContext);
  const {getGlobalValue} = useContext(GlobalContext);
  const [steps, setSteps] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [insertPosition, setInsertPosition] = useState(null);
  const [editingStepIndex, setEditingStepIndex] = useState(null);
  const [options, setOptions] = useState([]); // Add more options as needed
  const [stepResult, setStepResult] = useState([]);
  const [maxNumber, setMaxNumber] = useState(0);

  const [isSimulateVisible, setIsSimulateVisible] = useState(false);
  const [filterListData, setFilterListData] = useState([]);

  const lotteryListData = getGlobalValue("lotteryListData");
  

  useEffect(() => {
    console.log("lotteryListData", lotteryListData)
    if (lotteryListData !== null && lotteryListData !== undefined) {

   const selectedLottery = getGlobalValue("selectedLottery");
   if (lotteryListData.hasOwnProperty(selectedLottery)) {
      setMaxNumber(lotteryListData[selectedLottery].max);
   }
}

  }, [lotteryListData, getGlobalValue("selectedLottery")]);

  const handleSimulateClick = () => {
    setIsSimulateVisible(!isSimulateVisible);
  };

  const addStep = () => {
    let settingsObject = {};
    if (selectedOption.settings) {
      Object.entries(selectedOption.settings).forEach(([key, setting]) => {
        settingsObject[key] = {value:setting.value, values:setting.values, name:setting.name, type:setting.type, limit:setting.limit};
      });
    }
  
    const uniqueId = Date.now();

  if (editingStepIndex !== null) {
    setSteps(prevSteps => prevSteps.map((step, index) => index === editingStepIndex ? {...selectedOption, settings: settingsObject, idq: uniqueId} : step));
  } else {
    setSteps(prevSteps => [
      ...prevSteps.slice(0, insertPosition + 1),
      {...selectedOption, settings: settingsObject, idq: uniqueId},
      ...prevSteps.slice(insertPosition + 1)
    ]);
  }
    //setOptions(prevOptions => prevOptions.filter(option => option.name !== selectedOption.name));
    //setSelectedOption(null);
    setIsModalVisible(false);
  };

  const addStepPattern= (index) => {

    const uniqueId = Date.now();
    setSteps(prevSteps => [
      ...prevSteps.slice(0, index + 1),
      {
    "action": "add",
    "group": "systemnumbers",
    "id": "pattern",
    "name": "Number Patterns",
    "pause": true,
    "settings": {
        "pattern": {
            "value": "0;",
            "name": "Pattern",
            "type": "pattern"
        }
    },
    "idq": uniqueId

  }]);
  }

    const removeStep = (idq) => {
    setSteps(steps.filter(step => step.idq !== idq));
  };

  const pauseStep = (idq) => {
    setSteps(steps.map((step, index) => step.idq === idq ? {...step, pause: step.pause === true ? false : true} : step));
  }

  const openModal = (index, isEditing = false) => {
    setInsertPosition(index);
    setSelectedOption(isEditing ? steps[index] : options[0]);
    setEditingStepIndex(isEditing ? index : null);
    setIsModalVisible(true);
  };

  const handleInputChange = (id, value) => {
    setSelectedOption(prevOption => {
      const newSettings = Object.fromEntries(
        Object.entries(prevOption.settings).map(([key, setting]) => {
          if (key === id) {
            return [key, { ...setting, value }];
          }
          return [key, setting];
        })
      );
      return { ...prevOption, settings: newSettings };
    });
  };

   const handleInputChangeStep = (step, id, value) => {

    if (value === undefined || value === "0") return;

    setSteps(prevSteps => prevSteps.map((stepItem, index) => 
    stepItem.idq === step.idq 
        ? {
            ...stepItem, 
            pause: false, // set pause to false
            settings: Object.fromEntries(
                Object.entries(stepItem.settings).map(([key, setting]) => { 
                    if (key === id) { 
                        return [key, { ...setting, value }]; 
                    } 
                    return [key, setting]; 
                })
            )
        } 
        : stepItem
));
  };

  useEffect(() => {
    if (steps === null) return;
    console.log("steps1", steps)
    localStorage.setItem('laststepspatterns', JSON.stringify(steps));

  }, [steps]);

  useEffect(() => {
    console.log("selectedOption", selectedOption)
  }, [selectedOption]);

 useEffect(() => {  
  //if (options.length === 0) return;


  setSelectedOption(options.filter(option => option.id === 'pattern')[0]);
 
 }, [options]);

  useEffect(() => {
    
    setOptions(filterListData.filter(option => option.id === 'pattern' ))
    
  }, [filterListData]);


  useEffect(() => {
    
    filterList({ type: 'filter', setReturn: setFilterListData});
    const storedSteps = JSON.parse(localStorage.getItem('laststepspatterns'));
    console.log("storedSteps", storedSteps)
    setSteps(storedSteps);
    if (storedSteps?.length > 0) {
      console.log("storedSteps", storedSteps)
      setSteps(storedSteps);
    } else {
      console.log("setdefault")
      setSteps(defaultsteps);
        }
    

  }, []);

  const resetSteps = () => {
    console.log("reset")
    setSteps(defaultsteps);

  }

  return (<>

  
  <div style={{ padding:0, maxWidth:'99vw'}}>

    
    <div >
      <h2>{t.patterns}<span><Button style={{borderStyle:'none'}} onClick={resetSteps}>[{t.reset}]</Button></span></h2>

           

      <div style={{display:'flex', gap:15, flexWrap:'wrap'}}>
      {steps?.map((step, index) => (
<>
<div className='patternBox'>
        
          <Form>
   
  
    {Object.entries(step.settings).map(([key, setting]) => (
     <> 
     <div>{setting.name + " "+(index+1)}
     <Button type="link" icon={<DeleteOutlined/>} onClick={() => removeStep(step.idq)}/>
     <Button
          type="link"
          icon={step.pause ? <PlayCircleOutlined style={{fontSize: 17}}/>: <PauseCircleOutlined style={{fontSize: 17}}/>}
          onClick={() => pauseStep(step.idq)}
          style={{ color: step.pause ? 'rgb(22, 119, 255)' : 'grey', }}
        />
        </div>
      <Form.Item className="form-item-label-above" key={key} name={key}>
  
      {setting.type === 'number' ? (
    <InputNumber min={1} max={setting.limit} defaultValue={setting.value} onChange={value => handleInputChange(key, value)} />
  ) : setting.type === 'text' ? (
    <Input type="text" defaultValue={setting.value} onChange={e => handleInputChange(key, e.target.value)} />
  ) : setting.type === 'segment' ? (
    <Segmented options={setting.values} defaultValue={setting.value} value={setting.value} onChange={value => handleInputChange(key, value)} />
  
    ) : setting.type === 'pattern' ? (  
      
      <NumberPattern pattern={setting.value} onChange={value => handleInputChangeStep(step, key, value)}/>
  ) : (
    <Checkbox checked={setting.value} onChange={e => handleInputChange(key, e.target.checked)} />
  )}
</Form.Item>
</>
))}

  </Form>
  <StepComponent step={step} stepResult={stepResult} name={step.name} settings={step.settings} removeStep={removeStep} pauseStep={pauseStep} onSettingsClick={() => openModal(index, true)} />
  </div>
  </>
  
      ))}
<div style={{display:'flex'}}>
<Tooltip color='green' placement='right' open={steps?.length===1} title={t.addfirstpattern} defaultOpen ><Button onClick={() => addStepPattern(steps?.length)} style={{borderStyle:'none', margin:'auto'}}><PlusOutlined style={{fontSize:30}}/></Button></Tooltip>
</div>
      </div>
      
    
     
</div>


      {maxNumber>0 &&
      <div style={{paddingTop:20}}>
      <Draws steps={steps} t={t} maxnumber={maxNumber} setStepResult={setStepResult}></Draws>
      </div>
      } 
    </div>

    </>
  );
}

export default Simulator;