import React, { useContext, useEffect, useState } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import LanguageContext from '../../LanguageContext';
import { useTheme, ValueFormat, useValueFormatter } from '@nivo/core'
import { Chip } from './chip'

// Mock data similar to the uploaded image


const theme = {
  fontSize: 12,
  axis: {
    legend: {
      text: {
        fontSize: 16,
        fontWeight: 'bold',
      },
    },
  },
};

const BarChartComponent = ({draws, lotteryListDataDetails}) => {

    const [data, setData] = useState([]);
    const { t } = useContext(LanguageContext);

    function calculateCountsAndRanks(inputDict) {
        let counts = {};
        let total = 0;
        let count = 0;
        let numbersRow = lotteryListDataDetails?.numbers
        let maxnumber = lotteryListDataDetails?.max
        for (let key in inputDict) {
            for (let num of inputDict[key]) {
                if (counts[num]) {
                    counts[num]++;
                } else {
                    counts[num] = 1;
                }
                total += counts[num];
                
            }
            count++;
        }
        //console.log(total, count, numbersRow/maxnumber)
        let average = count * numbersRow / maxnumber;
    
        let sortedCounts = Object.entries(counts).sort((a, b) => Number(a[0]) - Number(b[0]));
    
        let outputList = sortedCounts.map((item, index) => ({
            number: item[0],
            Hits: item[1],
            Rank: index + 1,
        }));
    
        //console.log(outputList, average)
        return { outputList, average };
    }
    

  useEffect(() => {
    if (!draws) return;
    setData(calculateCountsAndRanks(draws))

  }, [draws]);

  

  return (
    <div style={{ height: '90vh', width:'90vw', minWidth:'500px' }}>
      <ResponsiveBar
        data={data.outputList}
        keys={['Hits']}
        indexBy="number"
        margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
        padding={0.3}
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        colors={(bar) => (bar.data.Hits > data.average ? 'green' : 'red')}
        defs={[
          {
            id: 'dots',
            type: 'patternDots',
            background: 'inherit',
            color: '#38bcb2',
            size: 4,
            padding: 1,
            stagger: true,
          },
          {
            id: 'lines',
            type: 'patternLines',
            background: 'inherit',
            color: '#eed312',
            rotation: -45,
            lineWidth: 6,
            spacing: 10,
          },
        ]}
        borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: '',
          legendPosition: 'middle',
          legendOffset: 32,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: t.hits,
          legendPosition: 'middle',
          legendOffset: -40,
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        enableLabel={false}
        tooltip={(bar) => (
          <div style={{backgroundColor:'white', borderRadius:8, padding:5, boxShadow:'2px 2px 1px 2px rgb(0,0,0,0.5)'}}>
            
            {t.number}: {bar.data.number}<br/>
            {t.hits}: {bar.data.Hits}
          </div>
        )}
        layers={['grid', 'axes', 'bars', 'markers', 'legends', (props) => {
            const { yScale } = props;
            const averageLine = data.average;
            const textOffset = 10;
            return (
                <g>
                    <line
                        x1={0}
                        x2={props.width}
                        y1={yScale(averageLine)}
                        y2={yScale(averageLine)}
                        stroke="rgba(0, 0, 255, 0.5)"
                        strokeWidth={2}
                    />
                    <text
                x={props.width-170} // position at the end of the line
                y={yScale(averageLine) - textOffset} // position at the line level with offset
                //textAnchor="end" // align the text to the end
                dy=".32em" // vertically center the text
                fontSize={12} // set the font size
                fill="#000000" // set the text color
            >
                {averageLine.toFixed(1)} {t.averagehits}
            </text>
                </g>
            );
        }]}
        theme={theme}
        animate={true}
        motionStiffness={90}
        motionDamping={15}
      />
    </div>
  );
};

export default BarChartComponent;
