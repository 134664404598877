import React, { useContext, useEffect, useState }  from 'react';
import { Link } from 'react-router-dom';
import { login } from '../library/account-helper';
import { Form, Input, Button } from 'antd';
import LanguageContext from '../LanguageContext';
import { GlobalContext } from '../GlobalContext';
import './css/Register.css';

function Login() {

    const { language, t } = useContext(LanguageContext);
    const [email, setEmail] = React.useState("");
    const [password, setPassword] = useState('');
    const { setGlobalValue, getGlobalValue } = useContext(GlobalContext);
    const [errorMessage, setErrorMessage] = useState(false);

useEffect(() => {   
    if (getGlobalValue("loggedIn")) {
        window.location.href = "/";
    }

}, [getGlobalValue("loggedIn")]);


return (
 <div className='container'> 

 <Form className='login-form'
    onFinish={() => {

      login({ email, password, onSuccess: () => { window.location.href = "/" }, onError: (error) => { setErrorMessage(true); } });
      
    }}
 >
    <Form.Item>
      <Input placeholder="Email" onChange={e => setEmail(e.target.value)} />
    </Form.Item>
    <Form.Item>
      <Input.Password placeholder="Password" onChange={e => setPassword(e.target.value)} />
    </Form.Item>
    <Form.Item>
      <Button type="primary" htmlType="submit">Login</Button>
    </Form.Item>
    
    {errorMessage && <p style={{color:'red'}}>Error Login</p>}
    <p><Link to={`/${language}/register`}>{t.register}</Link></p>
    <p><Link to={`/${language}/forgotpassword`}>{t.forgotpassword}?</Link></p>
 </Form>
 </div>

  );



}

export default Login;