// components/Draws.js
import React, { useState, useEffect, useContext, useRef } from 'react';
import {Table, Spin, DatePicker, Radio} from 'antd';

import dayjs from "dayjs";
import {lotteryDrawsPatterns} from '../library/data-helper';
import { GlobalContext } from '../GlobalContext';
import './css/Draws.css'; 

const defaultDates = [
  dayjs().subtract(365, "day"), // start date
  dayjs(), // end date
];

const rangePresets = [
  
  {
    label: 'This Year',
    value: [dayjs().startOf('year'), dayjs()],
  },
  {
    label: 'Last 365 days',
    value: [dayjs().add(-365, 'd'), dayjs()],
  },
  {
    label: 'Last 2 Years',
    value: [dayjs().add(-365*2, 'd'), dayjs()],
  },
];

function Draws({steps, t, maxnumber, setStepResult}) {

  const {getGlobalValue} = useContext(GlobalContext);
  const [rowData, setRowData] = useState([]);
  const [draws, setDraws] = useState({});
  const [loading, setLoading] = useState(false);
  const numbers = Array.from({ length: maxnumber }, (_, i) => i + 1);
  const [dates, setDates] = useState([]);
  const handleDateRangeChange = (date, dateString) => {
    //setDates(prevDates => ({ ...prevDates, [type]: dateString }));
    setDates(dateString);
  };


  const divRef = useRef(null);

 


  const lotteryListData = getGlobalValue("lotteryListData");
  
var days = [];
if (lotteryListData === null || lotteryListData === undefined) {
   days = [];
}else{
   const selectedLottery = getGlobalValue("selectedLottery");
   if (lotteryListData.hasOwnProperty(selectedLottery)) {
      days = lotteryListData[selectedLottery].days;
   }
}

// State variable for selected day
const [selectedDay, setSelectedDay] = useState(days[0]?.value);


  const columns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      className: 'first-column',
      width: 30,
    },
    ...numbers.map((num, index) => ({
      title: String(num),
      dataIndex: String(num),
      key: String(num),
      width:10,
      className: index % 2 === 0 ? 'light-column' : 'normal-column', // Add the CSS class to every second column
      render: (text, record) => {
        const isHighlighted = record.highlight?.includes(num);
        const isDrawn = record.numbers.includes(num);
        return {
          props: {
            style: { backgroundColor: isHighlighted ? (isDrawn ? 'lightgreen' : 'lightblue') : 'inherit' },
          },
          children: <div>{text}</div>,
        };
      },
    })),
  ];

    
  useEffect(() => {
        
    setDates([
          defaultDates[0].format("YYYY-MM-DD"),
          defaultDates[1].format("YYYY-MM-DD"),
        ]);
    
 
      }, []);

  useEffect(() => {
    if (!getGlobalValue("selectedLottery")) return;

    if (!dates || dates.length != 2) {
      return;
    }

    lotteryDrawsPatterns({steps:steps, selectedDay:selectedDay, lottery: getGlobalValue("selectedLottery"), startDate: dates[0], endDate: dates[1], day: selectedDay, setReturn: setDraws, setLoading: setLoading});
  }, [dates, steps, selectedDay, getGlobalValue("selectedLottery")]);
  useEffect(() => {
    if (divRef.current) {
      //console.log("scrolling");
      // Add a delay before scrolling
      if (divRef.current) {
          divRef.current.scrollTo(0, divRef.current.scrollHeight);
        }
      setTimeout(() => {
        
      }, 100);
    }
  }, [rowData]);

    useEffect(() => {
      //console.log(draws)

      if (!draws?.response) return;

      setStepResult(draws?.response)

      
      const data = draws?.response.map((draw, index) => {
        const row = { key: index, date: draw.date, highlight: draw.highlight, numbers: draw.numbers};
      
        draw.numbers.forEach((num) => {
          row[String(num)] = draw.numbers.includes(num) ? num : '';
        });
      
        return row;
      });

      

  setRowData(data);

  
  
  }, [draws]);

  
  return (
  <>
  <div style={{ height: '100vh', width: '99vw' }}>
  <div className='filters'>
  <div className='filters__option'>
  <h2>Draws</h2>
  </div>
  <div className='filters__option'>
     <label>{t.datefilter}</label>
  <DatePicker.RangePicker
  showWeek
  presets={rangePresets}
  defaultDates={defaultDates}
  value={dates.map(date => dayjs(date))}
  onCalendarChange={handleDateRangeChange}
  allowClear={false}
/>

</div>
     
      
      <div className='filters__option'>
      <label>{t.dayfilter}</label>
      <Radio.Group onChange={e => setSelectedDay(e.target.value)} value={selectedDay}>
        {days.map((day, index) => (
          <Radio.Button key={index} value={day.value}>{t[day.day]}</Radio.Button>
        ))}
      </Radio.Group>
      </div>
    </div>
  
  <div ref={divRef} style={{paddingBottom:40, overflowY:'auto', height:'50%'} } >
  {loading ? (<Spin size="large" />):
  (
  <Table className="compact-table" size="small" columns={columns} dataSource={rowData} rowClassName="bordered-cell" pagination={false} sticky scroll={{ x: 'max-content' }}/>
)}
  </div>
  </div>
  </>
  );
}

export default Draws;