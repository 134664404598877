// App.js
import React from 'react';
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import Login from './components/Login';
import Strategy from './components/Strategy';
import Draws from './components/DrawsPatterns';
import Statistics from './components/Statistics';
import Register from './components/Register';
import Navigation from './components/Navigation';
import { GlobalContext } from './GlobalContext';
import LanguageContext from './LanguageContext';
import { translations, supportedLanguages } from './Translations';
import { checkLogin, getsubscriptionsactive, getsubscriptionPlans, getUserData } from './library/account-helper';
import { lotteryList } from './library/data-helper';
import SystemBuilder from './components/SystemBuilder';
import TestDrawer from './components/TestDrawer';
import ForgotPassword from './components/ForgotPassword';
import Subscribe from './components/Subscribe';
import SettingsPage from './components/Settings';
import Imprint from './components/Imprint';
import Privacy from './components/Privacy';
import Terms from './components/Terms';


function App() {
  const [globalDict, setGlobalDict] = useState({});
  const [language, setLanguage] = useState('en');
  const [lotteryListData, setLotteryListData] = useState(null);
  const [subscriptionsActive, setSubscriptionsActive] = useState(null);
  const [subscriptionPlans, setSubscriptionPlans] = useState(null);

  
  let { path, lang } = useParams();


  const contextValue = {
    language,
    setLanguage,
    t: translations[language]

  };

  const getGlobalValue = (key) => {
    return globalDict[key];
  };

  const setGlobalValue = (key, value) => {
    setGlobalDict(prevDict => ({
      ...prevDict,
      [key]: value
    }));
  };

  useEffect(() => {
    
    var userLang = navigator.language || navigator.userLanguage; 
    userLang = userLang.split("-")[0];
  
    let selectedLanguage = 'en'; // Default to English
  
    // If the URL's language parameter is supported, use it
    if (lang && supportedLanguages.includes(lang)) {
      selectedLanguage = lang;
    }
    // Otherwise, if the browser's language is supported, use it
    else if (supportedLanguages.includes(userLang)) {
      selectedLanguage = userLang;
    }
  
    setLanguage(selectedLanguage);
    setGlobalValue("loggedIn", checkLogin());
    
    setGlobalValue("refreshData", false);

    //window.location.href  = "/" + selectedLanguage;
  }, []);

  function LanguageRedirect() {
    
    const navigate = useNavigate();
    const location = useLocation();

    console.log("Redirecting to " + language + location.pathname);
    //navigate(`/${language}/${path}`);
    //navigate(`/${language}${location.pathname}`);
    useEffect(() => {
      if (!lang) {
        //const language = navigator.language.split('-')[0]; // Get the first part of the language code
        navigate(`/${language}${location.pathname}`);
      }
    }, []);

    //return null;
  }


  useEffect(() => {

    if (lotteryListData==null) {
      lotteryList({setReturn:setLotteryListData});
    }

  if (lotteryListData !== null && lotteryListData !== undefined) {
   
    setGlobalValue('lotteryListData', lotteryListData);

  }
}, [lotteryListData]);

useEffect(() => {

  if (subscriptionsActive == null || getGlobalValue("refreshData")===true) {
    getsubscriptionsactive({setReturn:setSubscriptionsActive});
    setGlobalValue("refreshData", false);
    return;
  }

  if (subscriptionsActive !== null && subscriptionsActive !== undefined) {
    setGlobalValue('subscriptionsActive', subscriptionsActive);
  }
}, [subscriptionsActive, getGlobalValue("refreshData")]);

useEffect(() => {

  if (subscriptionPlans == null) {
   
    getsubscriptionPlans({setReturn:setSubscriptionPlans});

  }

  if (subscriptionPlans !== null && subscriptionPlans !== undefined) {
   
    setGlobalValue('subscriptionPlans', subscriptionPlans);

  }

}, [subscriptionPlans]);

 useEffect(() => {

  if (!getGlobalValue("loggedIn") || !getGlobalValue("loggedIn")){ return}

  getsubscriptionsactive({setReturn:setGlobalValue("subscriptionsActive")});

  getUserData({onSuccess: (data) => {setGlobalValue("userData", data)}, onError: (error) => {console.log(error)}});

 }, [getGlobalValue("loggedIn")]);

  return (
    <LanguageContext.Provider value={contextValue}>
    <GlobalContext.Provider value={{ globalDict, getGlobalValue, setGlobalValue }}>
    <Router>
      <Navigation />
      <div style={{alignContent:'center', width:'100%', padding:5, margin: 'auto'}}>
      <Routes>
        <Route path="/:lang/" element={<Home />} />
        <Route path="/:lang/strategy" element={<Strategy />} />
        <Route path="/:lang/patternfinder" element={<Draws />} />
        <Route path="/:lang/register" element={<Register />} />
        <Route path="/:lang/systembuilder" element={<SystemBuilder />} />
        <Route path="/:lang/login" element={<Login />} />
        <Route path="/:lang/forgotpassword" element={<ForgotPassword />} />
        <Route path="/:lang/testdrawer" element={<TestDrawer />} />
        <Route path="/:lang/statistics" element={<Statistics />} />
        <Route path="/:lang/subscribe" element={<Subscribe/>} />
        <Route path="/:lang/settings" element={<SettingsPage />} />
        <Route path="/:lang/imprint" element={<Imprint />} />
        <Route path="/:lang/privacy" element={<Privacy />} />
        <Route path="/:lang/terms" element={<Terms />} />
        <Route path="/*" element={<LanguageRedirect />} />
      </Routes>
      </div>
    </Router>
    
    </GlobalContext.Provider>
    </LanguageContext.Provider>
  );
}

export default App;